import React, { Component } from 'react'
import Loading from '../Loading'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import axios from 'axios'
import {APIURL, PORT} from '../../config/config'
import { connect } from 'react-redux'
import { save } from 'save-file';
import { AlertWarning } from '../Alert/Alert'
import {
  getRefundDifference,
  getSumCostAfterSell,
  getSumCostBeforeSell,
  getSumCostBeforeSellNoRepair,
  getSumCostFinanceAfterSell, getSumCostSellPayByCustomer
} from "../../services/costProfitService";

class TradingReport extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),
      lists: [],
      bank: [],
      bank_finance_id: '',
    }
    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.loadSellData = this.loadSellData.bind(this)
    this.loadBankLeasingData = this.loadBankLeasingData.bind(this)
  }

  componentDidMount() {
    this.loadBankLeasingData();
  }

  loadBankLeasingData () {
    // axios.get(APIURL + '/bank/')
    axios.get(APIURL + '/bank/get/is_leasing')
        .then(res => {
          this.setState({bank: res.data})
          //alert (res.data)
        }).catch(error => {
      this.setState({bank: []})
    })
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadSellData() {
    // const {date_start, date_end} = this.state

    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    // const data = {
    //   start_date,
    //   end_date
    // } buy_car_from
    this.setState({
      isLoading: true
    })

    let bank_finance_id = this.state.bank_finance_id
    let criteria = {
      bank_finance_id : bank_finance_id,
      start_date: start_date,
      end_date: end_date
    }

    // axios.get(APIURL + '/report/trading/'+start_date+'/'+end_date)
    axios.post(APIURL + '/report/trading_criteria', criteria)
      .then(res => {

        this.setState({
          lists: res.data,
          isLoading: false
        })
      }).catch(error => {
        console.log('error:', error)
       // this.setState({lists: []})
    })
  }

  exportSellData() {

    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    this.setState({
      isLoading: false
    })

    axios.get(APIURL + '/report/tradingexport/'+start_date+'/'+end_date)
      .then( async res => {
        const excelBuffer = res.data.excelBuffer
        const currentDatetime = res.data.currentDatetime
        const fileName = 'รายงานการซื้อขาย_export_'+currentDatetime+'.xlsx'
        this.setState({
          isLoading: false
        })
        await save(excelBuffer, fileName)

      }).catch(error => {
      console.log('error:', error)
      // this.setState({lists: []})
    })
  }

  render () {
    const {
      lists,
      sum_total_car_cost,
    } = this.state

    let sum_sell_price = 0
    let sum_car_cost = 0
    let sum_gross_profit = 0
    let sum_expense_total = 0
    let sum_amount_of_debt = 0
    let sum_income_other = 0

    const emptyBody = <tr><td colSpan={17} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    const listRows = lists.map((car, index) => {
      const car_sell_date = moment(car.car_sell_date).locale('th').format('LL')
      const car_date_in = moment(car.car_date_in).locale('th').format('LL')
      const buy_car_from = car.buy_car_from
      const customer_fullname = car.customer_name + ' ' + car.customer_lastname
      const car_sell_type_name = car.car_sell_type_name
      const bank_name = car.bank_name

      const expense_before_total = getSumCostBeforeSellNoRepair(car) //ค่าใช้จ่ายซื้อเข้า
      let car_cost = car.car_cost + car.vat_buy + expense_before_total // ต้นทุนซื้อเข้า

      const vat_invoice = car.vat_sell > 0 ? car.vat_sell : car.tax_invoice_vat //VAT ยอดจัด
      const finance_commission = car.tax_commission_amount > 0 ? car.tax_commission_amount : car.finance_commission  //ค่าคอม
      const vat_commission = car.tax_commission_vat > 0 ? car.tax_commission_vat : car.vat_commission  //VAT ค่าคอม

      const car_sell_type = car.car_sell_type_id
      const car_cost_repair = car.car_cost_repair

      let sell_price = 0
      if(car_sell_type === 1){
        sell_price = car.tax_invoice_amount === null || car.tax_invoice_amount === 0 ? car.real_sell_price : car.tax_invoice_amount
      } else if (car_sell_type === 2) {
        // จัดไฟแนนซ์
        sell_price = car.tax_invoice_amount === null || car.tax_invoice_amount === 0 ? car.rate_finance : car.tax_invoice_amount
        //ปรับแก้ 17-10-67 ปิดไว้เพราะเพิ่มช่องรายรับเงินดาวน์ขึ้นใหม่
        /*if (sum_down !== 0 ) {
            sell_price += sum_down
        }*/
      }

      if(PORT === 9005 || PORT === 9008 || PORT === 9063 || PORT === 9046){
        sell_price = car.real_sell_price
      }

      if(PORT === 9048){
        if(car_sell_type === 1){
          sell_price = car.tax_invoice_amount === null || car.tax_invoice_amount === 0 ? car.real_sell_price : car.tax_invoice_amount
        } else {
          sell_price = car.tax_invoice_amount === null || car.tax_invoice_amount === 0 ? car.rate_finance : car.tax_invoice_amount
        }
      }

      // รายรับ
      const sum_down = car.sum_down //ยอดดาวน์
      let income_other = vat_invoice + finance_commission + vat_commission+Number(sum_down)

      if (PORT === 9008) {
        car_cost = car.car_cost
        income_other = finance_commission
      }


      let car_license_plate = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ? car.car_license_plate_new : car.car_license_plate_old

      // let car_cost_repair = car.car_cost_repair;
      const expense_after_sell = getSumCostAfterSell(car)
      const expense_after_finance = getSumCostFinanceAfterSell(car)

      const expense_total = Number(car_cost_repair) + Number(expense_after_sell) + Number(expense_after_finance)
      const amount_of_debt = Number(car.amount_of_debt)

      let {refund_difference, refund_cash} = getRefundDifference(car)
      let car_excess_amount_use = getSumCostSellPayByCustomer(car)

      let gross_profit = (sell_price + income_other + refund_difference) -(car_cost + expense_total + refund_cash + car_excess_amount_use)

      if (PORT !==9048) {
        gross_profit = gross_profit - amount_of_debt
      }

      sum_sell_price += sell_price
      sum_car_cost += car_cost
      sum_gross_profit += gross_profit
      sum_expense_total += expense_total
      sum_amount_of_debt += amount_of_debt
      sum_income_other += income_other

      const province_name = car.car_license_plate_new !== '' ||  car.car_license_plate_new === 999 &&  car.car_license_plate_new !==  car.car_license_plate_old ? car.province_name_new : car.province_name_old


      let customer_mobile = car.customer_mobile
      let customer_address = car.customer_address
      return (
        <tr key={index} style={index%2===0? { backgroundColor: ''} : { backgroundColor: '#e4e5e6'}}>
          <td className="text-center">{index+1}</td>
          <td className="text-nowrap" style={{width: 220}}>{car.car_name}</td>
          <td className="text-center text-nowrap" style={{width: 120}} >{car_license_plate} <br/> {province_name}</td>
          <td className="text-center text-nowrap" style={{width: 170}} >{car_date_in}</td>
          <td className="text-center text-nowrap" style={{width: 120}} >{buy_car_from}</td>
          <td className="text-center text-nowrap" style={{width: 170}} >{car_sell_date}</td>
          <td className="text-nowrap" style={{width: 300}} >{customer_fullname}</td>
          <td className="text-nowrap" style={{width: 300}} >{customer_mobile}</td>
          <td className="text-nowrap" style={{width: 300}} >{customer_address}</td>
          <td className="text-center text-nowrap" style={{width: 120}} >{car_sell_type_name}</td>
          <td className="text-nowrap" style={{width: 120}} >{bank_name}</td>
          <td className="text-nowrap" style={{width: 140}} align="right" >{sell_price.toLocaleString()}</td>
          <td className="text-nowrap" style={{width: 140}} align="right" >{car_cost.toLocaleString()}</td>
          <td className="text-nowrap" style={{width: 140}} align="right" >{income_other.toLocaleString()}</td>
          <td className="text-nowrap" style={{width: 140}} align="right" >{expense_total.toLocaleString()}</td>
          <td className="text-nowrap text-danger" style={{width: 140}} align="right" >-{amount_of_debt.toLocaleString()}</td>
          <td className="text-nowrap" style={{width: 140}} align="right" >{gross_profit.toLocaleString()}</td>
          <td className="text-center text-nowrap" style={{width: 130}} >{car.employee_name}</td>
          {/*<td style={{width: 60}} className="text-center">{car.employee_name}</td>*/}
          {/*<td className="text-right">{car.normal_sell_price === null ? '-' : car.normal_sell_price.toLocaleString()}</td>*/}
          {/*<td className="text-right">{car.normal_sell_price === null ? '-' : car.normal_sell_price.toLocaleString()}</td>*/}

        </tr>
      )
    })

    const tableRows = lists.length === 0 ? emptyBody : listRows

    const tableSum = <tr>
                  <td className="text-right font-weight-bold" colSpan={11}>รวม</td>
                  <td className="text-right font-weight-bold">{sum_sell_price.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_car_cost.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_income_other.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_expense_total.toLocaleString()}</td>
                  <td className="text-right font-weight-bold text-danger">-{sum_amount_of_debt.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_gross_profit.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{''}</td>
                </tr>

    let bank_finance_list = this.state.bank.map((bank, index) => {
      return (
          <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
      )
    })

    return (
      <div>
        <Loading isLoading={this.state.isLoading} />
        <div className="card-block" style={styles.overflowY}>
          <div className="row">
              <div className="card card-accent-primary">
                <div className="card-header">
                  <strong className="text-title">รายงานการซื้อขาย</strong>
                </div>
                <div className="card-block">

                  <div className="row mt-1">
                    <div className="col-1 mt-1 text-right">
                      จาก
                    </div>

                    <div className="col-2">
                      <DatePicker
                        selected={this.state.date_start}
                        onChange={this.handleDateStart}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>

                    <div className="mt-1 text-right">
                      ถึง
                    </div>

                    <div className="col-2 ml-3">
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control d-block"
                        placeholder=""
                      />
                    </div>

                    <div className="form-group ml-2 mr-2" style={{width: 160}}>
                      <select
                          className="form-control"
                          id="bank_finance_id"
                          name="bank_finance_id"
                          onChange={(e) => {
                            this.setState({
                              bank_finance_id : e.target.value
                            });
                          }}
                      >
                        <option value=""> เลือกไฟแนนซ์</option>
                        {bank_finance_list}
                      </select>
                    </div>

                    <div className="d-flex mr-3">
                      <button type="button" className="btn btn-sm btn-primary" onClick={() => this.loadSellData()} > ตกลง </button>
                    </div>

                    <div className="d-flex">
                      <button type="button" className="btn btn-sm btn-success" onClick={() => this.exportSellData()} > Excel </button>
                    </div>

                    <div className="col-4 text-right pt-2">
                      {/*<h5>
                        รวมยอดขาย&nbsp;
                        <NumberFormat
                          displayType={'text'}
                          thousandSeparator={true}
                          value={this.state.sum_expense}/>
                        &nbsp;บาท
                      </h5>*/}
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12">
                      <table className="" border={1} style={{border: 1, minHeight: 250}} >
                        <thead>
                          <tr>
                            <th  style={styles.headerTable} >ลำดับ</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 220}}}  >ชื่อรถ</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >ทะเบียนรถ</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >วันที่ซื้อรถเข้า</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >ซื้อรถจาก</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >วันที่ขาย</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >ลูกค้า</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >เบอร์โทรลูกค้า</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >ที่อยู่ลูกค้า</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >ประเภทการขาย</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >ไฟแนนซ์</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 140}}} >ราคาขายจริง <br/>/ยอดจัด</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 140}}} >
                              {
                                PORT === 9008 ? <> ราคาซื้อเข้า </> : <> ทุนซื้อเข้า <br/> รวม VAT </>
                              }
                            </th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 140}}} >รวมรายรับ <br/> อื่นๆของรถ</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 140}}} >รวมค่าใช้จ่าย <br/> และค่าซ่อม</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 140}}} >ตั้งหนี้</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 140}}} >กำไรเบื้องต้น</th>
                            <th className="text-nowrap" style={{...styles.headerTable,...{width: 120}}} >ขายโดย</th>
                          </tr>
                        </thead>
                        <tbody>
                         {tableRows}
                         {tableSum}
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>

          </div>
        </div>

      </div>
    )
  }
}


const styles = {
  headerTable: {
    backgroundColor: '#eeede8',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowReceive: {
    backgroundColor: '#bfcad0',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowExpense: {
    backgroundColor: '#ede4e3',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowProfit: {
    backgroundColor: '#95E06C',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

// export default SellReport;
export default connect(mapStateToProps)(TradingReport);
